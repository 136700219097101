<template>
  <iframe :src="src" width="100%" :height="clientHeight"></iframe>
</template>

<script>
export default {
  name: "pdfPreview",
  props: {
    documentUrl: {
      required: true,
      type: String,
      default: ""
    }
  },
  data() {
    return {
      clientHeight: "auto"
    }
  },
  mounted() {
    this.$nextTick(()=>{
      this.clientHeight = document.documentElement.clientHeight-150
    })
  },
  computed: {
    src() {
      return `/lib/pdfjs/web/viewer.html?file=${this.documentUrl}`
    }
  }
}
</script>

<style scoped>

</style>
