<template>
  <div>
    <pdfPreview :document-url="documentUrl"></pdfPreview>
  </div>
</template>

<script>
import pdfPreview from "@/components/PdfPreview";
export default {
  name: "Index",
  components: {
    pdfPreview
  },
  data() {
    return {
      documentUrl: "https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/cvh/cvh2023/China%20Valve%20(Hangzhou)%202023%E9%80%9A%E7%9F%A5.pdf"
    }
  }
}
</script>

<style scoped>

</style>
